import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  PaymentRequestButtonElement,
  useStripe,
} from "@stripe/react-stripe-js";
import { getCommonHeaders, usePersistedState } from "../Utils";

const promise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const PaymentRequestForm = (props) => {
  const { onValidate } = props;
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [getProductDetails, setProductDetails] = usePersistedState(
    "product-details",
    {}
  );

  const paymentSuccess = async (paymentIntent) => {
    const config = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ payment_intent_id: paymentIntent.id }),
    };
    const fetchResponse = await fetch(`/stripe/complete-intent`, config);
    const data = await fetchResponse.json();
    console.log(data);
    if (data.success) window.location.href = "/success?token=" + data.token;
    else {
      alert("failed to complete the order");
    }
  };

  const onPaymentMethod = async (ev) => {
    const intentResp = await fetch("/stripe/request-intent", {
      method: "POST",
      headers: getCommonHeaders(),
      body: JSON.stringify({
        data: ev,
        source: props.source,
        productDetails: props.source == "product" ? getProductDetails() : {},
      }),
    });
    const intent = await intentResp.json();
    const clientSecret = intent.client_secret;

    // Confirm the PaymentIntent without handling potential next actions (yet).
    const { paymentIntent, error: confirmError } =
      await stripe.confirmCardPayment(
        clientSecret,
        { payment_method: ev.paymentMethod.id },
        { handleActions: false }
      );

    if (confirmError) {
      ev.complete("fail");
    } else {
      ev.complete("success");
      if (paymentIntent.status === "requires_action") {
        const { error } = await stripe.confirmCardPayment(clientSecret);
        if (error) {
          console.error(error);
        } else {
          await paymentSuccess(paymentIntent);
        }
      } else {
        await paymentSuccess(paymentIntent);
      }
    }
  };

  const onCancel = () => {
    window.location.reload();
  };

  const handleOnClick = (ev) => {
    if (!onValidate) {
      return false;
    }
    const [valid, data, total] = onValidate();
    if (!valid) {
      ev.preventDefault();
      return false;
    }
    setProductDetails(data);
    paymentRequest.update({
      currency: props.currency,
      total: {
        label: "Total",
        amount: parseInt(total * 100),
      },
    });
  };

  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: props.country,
        currency: props.currency,
        total: {
          label: "Total",
          amount: parseInt(props.amount * 100),
        },
        requestPayerName: true,
        requestPayerEmail: true,
        requestPayerPhone: true,
        requestShipping: true,
        shippingOptions: [
          {
            id: "free-shipping",
            label: "Free shipping",
            detail: "Applicable on all orders",
            amount: 0,
          },
        ],
      });

      pr.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pr);

          pr.on("paymentmethod", onPaymentMethod);
          pr.on("cancel", onCancel);
        }
      });
    }
  }, [stripe]);

  if (paymentRequest) {
    return (
      <PaymentRequestButtonElement
        className="stripe-product-payment-btn mbl"
        onClick={handleOnClick}
        options={{ paymentRequest }}
      />
    );
  }

  return null;
};

export default function StripePaymentRequest(props) {
  return (
    <Elements stripe={promise}>
      <PaymentRequestForm {...props} />
    </Elements>
  );
}
